<template>
  <div
    class="store-header-container"
    :class="{'new-cartrow-vision': isNewCartrowVision}"
  >
    <div class="store-header_left">
      <slot></slot>
      <div
        v-tap="analysisData('1-8-7-45')"
        v-expose="analysisData('1-8-7-46')"
        :data-ready="String(isReady)"
        class="store-header-content"
        @click="goToStore"
      >
        <div class="store-header">
          <template v-if="preferredSellerStore == 1">
            <div class="seller-store-icon">
              <ClientOnly>
                <preferred-seller-store-icon />
              </ClientOnly>
            </div>
          </template>
          <template v-else>
            <sui_icon_me_shop_15px
              v-if="isNewStoreIcon"
              size="15px"
              class="store-icon"
            />
            <sui_icon_store_15px
              v-else
              size="15px"
              class="old-store-icon"
            />
          </template>
          <span class="store-title">{{ store_title }}</span>
          <img
            v-if="!!trendIconUrl"
            class="trend-icon"
            :src="trendIconUrl"
          />
          <sui_icon_more_right_12px
            v-if="storeTitle != 'shein' && storeCode != 1"
            class="shop-icon_more"
            size="12px"
            :is-rotate="locals.GB_cssRight"
          />
          <!--          <s-label type="sellpoint" v-if="storeType == 2 && storeCode != 1" style="font-size: 10px"> {{ language.SHEIN_KEY_PWA_22082 }} </s-label>-->
        </div>
      </div>
    </div>

    <!-- 领取优惠券入口 -->
    <ClientOnly>
      <get-coupon
        v-if="showCoupon"
        class="store-get-coupon"
        :store-code="storeCode"
        :mall-code="mallCode"
        :store-type="storeType"
      />
    </ClientOnly>
  </div>
</template>

<script>import { mapGetters, mapState } from 'vuex'
import { stringifyQueryString } from '@shein/common-function'
import { sui_icon_me_shop_15px, sui_icon_more_right_12px, sui_icon_store_15px } from '@shein-aidc/icon-vue2'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { markPoint } from 'public/src/services/mark/index.js'
import getCoupon from '../coupon/getCoupon/index.vue'
import preferredSellerStoreIcon from '../preferredSellerStoreIcon.vue'
daEventCenter.addSubscriber({ modulecode: '1-8-7' })

export default {
  name: 'PlatformStoreHeader',
  components: {
    sui_icon_me_shop_15px,
    getCoupon,
    sui_icon_store_15px,
    sui_icon_more_right_12px,
    preferredSellerStoreIcon
  },
  props: {
    storeCode: {
      type: [String, Number],
      default: ''
    },
    mallCode: {
      type: [String, Number],
      default: ''
    },
    storeTitle: {
      type: String,
      default: ''
    },
    storeLogo: {
      type: String,
      default: ''
    },
    storeType: {
      type: [String, Number],
      default: ''
    },
    preferredSellerStore: {
      type: [String, Number],
      default: 0
    },
    showCoupon: {
      type: Boolean,
      default: true
    },
    disableClick: {
      type: Boolean,
      default: false
    },
    storeListOrigin: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      isReady: false,
    }
  },
  computed: {
    ...mapState('cart', ['storeSelectsInfo', 'locals', 'language']),
    ...mapGetters('cart', ['isNewStoreIcon', 'isNewCartrowVision', 'storeTrendLogoGroup']),
    store_title() {
      if(this.storeTitle == 'shein'){
        return this.locals.IS_RW ? 'ROMWE' : 'SHEIN'
      }
      return this.storeTitle
    },
    trendIconUrl() {
      return  this.storeTitle == 'shein' ? '' : this.storeTrendLogoGroup[this.storeCode] || ''
    }
  },
  watch: {
    storeTrendLogoGroup: {
      handler() {
        this.isReady = true
      },
      deep: true,
    }
  },
  methods: {
    analysisData(id) {
      return {
        id: id,
        once: false,
        waitReady: true,
        data: {
          store_code: this.storeCode,
          store_tp: this.storeType == 1 ? 1 : 2,
          preferred_seller: this.preferredSellerStore == 1 ? 1 : 0,
          store_label: this.trendIconUrl ? 'trends' : '-',
        }
      }
    },
    goToStore: function (){
      if(this.storeTitle == 'shein' || this.storeCode == 1 || this.disableClick){
        return
      }
      
      let url = this.storeSelectsInfo[this.storeCode]
      if(url){
        url = url + (url.indexOf('?') > -1 ? '&' : '?') + this.getStoreQuery() 
        markPoint('toNextPageClick', 'public')
        this.$router.push(gbCommonInfo.langPath + url)
      }
    },
    getStoreQuery() {
      const goodsIds = []
      const cateIds = []
      if (this.storeListOrigin && this.storeListOrigin.length) {
        this.storeListOrigin.forEach(item => {
          if (item.product) {
            goodsIds.push(item.product.goods_id)
            cateIds.push(item.product.cat_id)
          }
        })
      }
      return stringifyQueryString({
        queryObj: {
          rule_poskey: 'CartShopItemList',
          goods_ids: goodsIds.join(','),
          cate_ids: cateIds.join(','),
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.store-header-container {
  background-color: #FFFFFF;
  line-height: 17px;
  padding: 16/75rem 20/75rem 8/75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.new-cartrow-vision {
    padding-left: 16/75rem;
  }
  .store-header_left {
    display: flex;
    flex:1;
    align-items: center;
    overflow: hidden;
  }
  .store-header-content {
    position: relative;
    flex: 1;
    overflow: hidden;
  }
  .store-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    .store-icon {
      margin-right: 4/75rem;
    }
    .store-title {
      color: #222222;
      font-size: 14px;
      padding: 0 4/75rem 0;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      position: relative;
      bottom: 1/75rem;
    }
    .seller-store-icon {
      display: inline-block;
      width: 32/75rem;;
    }
    .old-store-icon {
      margin-right: 8/75rem;
    }
  }
  .store-get-coupon {
    margin-left: 8/37.5rem;
    white-space: nowrap;
  }
  .trend-icon {
    width: 37/37.5rem;
    height: 12/37.5rem;
    margin-left: 2/37.5rem;
  }
}
</style>
