
<template>
  <div
    v-tap="analysisData('1-8-7-45')"
    v-expose="analysisData('1-8-7-46')"
    :data-ready="String(isReady)"
    class="cart-store-header"
    @click="goToStore"
  >
    <template v-if="item.preferred_seller_store == 1">
      <ClientOnly>
        <PreferredSellerStoreIcon />
      </ClientOnly>
    </template>
    <template v-else>
      <sui_icon_me_shop_15px
        v-if="isNewStoreIcon"
        size="12px"
        class="store-icon"
      />
      <sui_icon_store_15px
        v-else
        size="15px"
        class="store-icon"
        color="#222"
      />
    </template>
    <span class="store-title">{{ storeTitle }}</span>
    <img
      v-if="!!trendIconUrl"
      class="trend-icon"
      :src="trendIconUrl"
    />
    <sui_icon_more_right_12px_2
      v-if="storeTitle != 'SHEIN' && storeCode != 1"
      size="12px"
      :is-rotate="locals.GB_cssRight"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { sui_icon_me_shop_15px, sui_icon_store_15px, sui_icon_more_right_12px_2 } from '@shein-aidc/icon-vue2'
import PreferredSellerStoreIcon from 'public/src/pages/cartNew/components/preferredSellerStoreIcon.vue'
import { markPoint } from 'public/src/services/mark/index.js'

export default {
  components: {
    sui_icon_me_shop_15px,
    PreferredSellerStoreIcon,
    sui_icon_store_15px,
    sui_icon_more_right_12px_2,
  },
  // 组件传参
  props: {
    item: {
      type: Object,
      default(){
        return {}
      }
    },
  },
  data() {
    return {
      isReady: false,
    }
  },
  // 计算属性
  computed: {
    ...mapState('cart', ['storeSelectsInfo', 'locals']),
    ...mapGetters('cart', ['isNewStoreIcon', 'storeTrendLogoItem']),
    siteName () {
      return this.locals.IS_RW ? 'ROMWE' : 'SHEIN'
    },
    storeTitle(){
      return this.item.store_title || this.siteName
    },
    storeCode(){
      return this.item.store_code
    },
    trendIconUrl() {
      return this.storeTrendLogoItem[this.storeCode] || ''
    }
  },
  watch: {
    storeTrendLogoItem: {
      handler() {
        this.isReady = true
      },
      deep: true,
    }
  },

  // 方法实现
  methods: {
    goToStore: function (){
      if(this.storeTitle == this.siteName || this.storeCode == 1){
        return
      }
      if(this.storeSelectsInfo[this.storeCode]){
        markPoint('toNextPageClick', 'public')
        this.$router.push(gbCommonInfo.langPath + this.storeSelectsInfo[this.storeCode])
      }
    },
    analysisData(id){
      return {
        id: id,
        once: false,
        waitReady: true,
        data: {
          store_code: this.storeCode,
          store_tp: this.item.store_type == 1 ? 1 : 2,
          preferred_seller: this.item.preferred_seller_store == 1 ? 1 : 0,
          store_label: this.trendIconUrl ? 'trends' : '-',
        }
      }
    }
  },
}
</script>

<style lang='less' scoped>
.cart-store-header{
  display: flex;
  align-items: center;
  line-height: 1;
  padding-bottom: 12/75rem;
  .store-icon {
    margin-right: 4/75rem;
  }
  .store-title {
    color: #222222;
    font-size: 11px;
    margin-right: 4/75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  .trend-icon {
    width: 37/37.5rem;
    height: 12/37.5rem;
    margin-left: 2/37.5rem;
  }
}
</style>
