<template>
  <s-title-nav>
    <template slot="preblock">
      <div
        class="new-cart-nav"
      >
        <div
          class="new-cart-nav__left"
          @click="batchActive ? '' : $router.back()"
        >
          <i
            v-show="!batchActive"
            class="j-go-back left-icon"
            
          >
            <sui_icon_nav_back_24px
              size="24px"
              :is-rotate="locals.GB_cssRight"
            />
          </i>
          <span class="new-cart-nav__title">{{ language.SHEIN_KEY_PWA_14965 }}</span>
          <span
            v-if="cartSum > 0"
            class="new-cart-nav__sum"
          >({{ cartSum }})</span>
        </div>
        <div class="newcomer-guide__address">
          <ClientOnly>
            <cart-address />
          </ClientOnly>
        </div>
      </div>
    </template>
    <template slot="endblock">
      <div
        v-show="!batchActive"
        class="cart-header-right"
      >
        <i
          v-if="showShare && cartShareStyle === 'off'"
          v-expose="{id: '1-8-1-37', data: { type: '-', sense: '-' }}"
          class="cart-header__icon-right j-share-guide-icon"
          @click="goShare({ type: '-', cartShareStyle, sense: '-' })"
        >
          <sui_icon_share_24px size="24px" />
        </i>
        <ShareSocialSwiper
          v-show="showShare && cartShareStyle !== 'off'"
          ref="shareSocialSwiper"
          cache-key="ShareSocialSwiperSlideCartPage"
          class="cart-header__icon-right cart-header__share-swiper"
          @click.native="goShare({ type: cartShareStyle, sense: '-' })"
        />
        <i
          v-show="carts.length > 1"
          ref="batchBtnRef"
          slot="reference"
          v-enterkey
          class="cart-header__icon-right j-batch-edit-btn"
          tabindex="0"
          :aria-label="language.SHEIN_KEY_PWA_17956"
          role="button"
          
          da-event-expose="1-8-7-6"
          da-event-click="1-8-7-9"
          @click="handleClickBatchEdit(0)"
        >
          <sui_icon_nav_select_24px size="24px" />
        </i>
        <i
          v-show="loginStatus"
          v-enterkey
          class="cart-header__icon-right cart-header-right-wishlist"
          href="javascript:void(0);"
          da-event-click="1-8-1-4"
          tabindex="0"
          :aria-label="language.SHEIN_KEY_PWA_15552"
          @click="handleClickWishlist()"
        >
          <sui_icon_nav_save_24px size="24px" />
        </i>
      </div>
      <div
        v-show="batchActive"
        class="cart-header-right"
      >
        <i
          ref="doneBtnRef"
          v-enterkey
          class="cart-header__right-done"
          href="javascript:void(0);"
          role="button"
          tabindex="0"
          :aria-label="language.SHEIN_KEY_PWA_15042"
          da-event-click="1-8-7-8"
          @click="handleClickBatchEdit(1)"
        >
          {{ language.SHEIN_KEY_PWA_15042 }}
        </i>
      </div>
    </template>
  </s-title-nav>
</template>


<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { daEventCenter } from '../../../../services/eventCenter'
import { markPoint } from 'public/src/services/mark/index.js'
import { sui_icon_nav_back_24px, sui_icon_nav_select_24px, sui_icon_nav_save_24px, sui_icon_share_24px} from '@shein-aidc/icon-vue2'

export default {
  name: 'CartNav',
  components: {
    sui_icon_nav_back_24px,
    sui_icon_nav_select_24px,
    sui_icon_nav_save_24px,
    sui_icon_share_24px,
    cartAddress: () => import(/* webpackChunkName: "cart-address" */'../cartAddress/index.vue'),
    ShareSocialSwiper: () => import(/* webpackChunkName: "share-social-swiper" */'../cartShareDraw/ShareSocialSwiper.vue')
  },
  computed: {
    ...mapState('cart', ['prerenderSsr', 'language', 'loginStatus', 'batchActive', 'showBatchEditPopover', 'cartAbtInfo', 'locals', 'showAddressSelectedHeight', 'isSSR', 'cartInfo', 'soldoutIds', 'monitorUserInteraction']),
    ...mapGetters('cart', ['carts']),
    popoverStyle: function () {
      const length = this.loginStatus ? '1.32rem' : '.32rem'
      return this.locals.GB_cssRight ? { left: length, right: 'auto', top: '1.17rem' } : { right: length, left: 'auto', top: '1.17rem' }
    },
    showShare() {
      return !this.batchActive && this.cartSum > 0 && !this.locals?.IS_RW
    },
    cartSum() {
      if (this.batchActive) {
        return this.carts.length
      }
      let normalItems = this.carts?.filter(item => !this.soldoutIds.includes(item.id)) || [] // 购物车内所有告罄商品
      return normalItems.length
    },
    cartShareStyle() {
      return this.cartAbtInfo?.['CartShare']?.param?.uxstyle ?? 'off'
    }
  },
  watch: {
    batchActive (n) {
      if (n) {
        daEventCenter.triggerNotice({ daId: '1-8-7-7' })
        this.$nextTick(() => {
          this.$refs.doneBtnRef.focus()
        })
      } else {
        this.$nextTick(() => {
          this.$refs.batchBtnRef.focus()
        })
      }

      this.handleShareSwiperStatus(n)
    }
  },
  methods: {
    ...mapMutations('cart', ['handleBatchActive', 'updateState', 'handleHash']),
    handleClickWishlist () {
      markPoint('toNextPageClick', 'public')
      this.$routerGoWish()
    },
    handleClickBatchEdit (v) {
      if (v === 1) {
        this.updateState({ key: 'filterType', value: 'none' })
        setTimeout(() => {
          $('.j-cart-list-filter')?.[0]?.scrollTo({ left: 0 })
        }, 0)
      }
      this.handleBatchActive({ checkSoldout: true })
    },
    goShare(extraData = {}) {
      daEventCenter.triggerNotice({
        daId: '1-8-1-38',
        extraData,
      })
      this.handleHash({
        hash: 'cartSharePageShow'
      })
      sessionStorage.removeItem('cartShareChecked') // 从购物车进入默认全部不勾选
    },

    /**
     * 处理社媒分享轮播状态 
     */
    handleShareSwiperStatus(isBatchActive) {
      this.$nextTick(() => {
        const shareSocialSwiper = this.$refs?.shareSocialSwiper
        if (!shareSocialSwiper) {
          console.log('My 渲染')
          return
        }

        // 播放完毕不处理
        const { isLoopDone } = shareSocialSwiper.getSwiperStatus()
        if (isLoopDone) return

        // 批量编时停止轮播
        if (isBatchActive) {
          shareSocialSwiper.stopSwiper()
        } else {
          shareSocialSwiper.startSwiper()
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
/* stylelint-disable */
.cart-header-right {
  .flexbox();
  .align-center();
  .txt-r();
  .font-dpr(28px);
  white-space: nowrap;
  a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    pointer-events: auto;
    line-height: 1;
    position: relative;
    > i, [class*="iconfont"] {
      .font-dpr(48px);
    }
  }
}
.cart-header-right-wishlist[class*="iconfont"] {
    .font-dpr(48px);
    touch-action: manipulation
}
.cart-header__right-done {
  .font-dpr(32px);
}
.cart-header__icon-right {
  .font-dpr(48px)!important;
  .margin-l(.32rem);
}
.cart-header__share-swiper {
  display: inline-block;
  line-height: 0;
}
.page-title {
  width: 100%;
  text-align: center;
  .text-overflow();
}
.newcomer-guide__address {
  width: 100%;
  text-align: center;
  .text-overflow();
  &.addr-wrapper-height{
    min-height: .6rem;
  }
}
.cart-nav {
  height: 1.49rem !important;
}
.new-cart-nav {
  display: flex;
  align-items: center;
  max-width: 6.6rem;
  &__left{
    display: flex;
    align-items: center;
    margin-right: 8/75rem;
    .left-icon {
      font-size: 24px;
      margin-right: 24/75rem;
      line-height: 0;
    }
  }
  &__title{
    font-size: 17px;
    font-weight: bold;
    white-space: nowrap;
  }
  &__sum{
    font-size: 12px;
    padding: 4/75rem 0 0 4/75rem;
    font-weight: bold;
    white-space: nowrap;
  }
}
</style>
