<script lang="jsx">
import { transformImg } from '@shein/common-function'
import ClientOnly from 'vue-client-only'
import { getMainImageUrl, getDiscountText } from 'public/src/pages/cartNew/utils/index.js'
import ImgStockBar from './ImgStockBar.vue'

export default {
  name: 'CartItemImgInfo',
  functional: true,
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    soldoutIds: {
      type: Array,
      default() {
        return []
      }
    },
    blackFridayImgGoods: {
      type: Object,
      default() {
        return {}
      }
    },
    locals: {
      type: Object,
      default() {
        return {}
      }
    },
    underPriceAbtValue: {
      type: Boolean,
      default: false
    },
    isFirstPage: {
      type: Boolean,
      default: false
    },
    baseCutImg: {
      type: String,
      default: ''
    },
    cartDiscountLogoA: {
      type: Boolean,
      default: false
    },
    isNewCartDiscountStyle: {
      type: Boolean,
      default: false,
    },
    customServiceImgDomains: {
      type: Array,
      default() {
        return []
      }
    },
    thirdPartyServiceImgDomains: {
      type: Array,
      default() {
        return []
      }
    },
    isNewPrice: {
      type: Boolean,
      default: false
    },
    isNewCartrowVision: {
      type: Boolean,
      default: false
    },
  },
  render(h, { props }) {
    // goods_img_placeholder
    const { item, isFirstPage, language, soldoutIds, blackFridayImgGoods, locals, underPriceAbtValue, baseCutImg, cartDiscountLogoA, isNewCartDiscountStyle, customServiceImgDomains, thirdPartyServiceImgDomains, isNewPrice, isNewCartrowVision } = props
    const { GB_cssRight, LAZY_IMG, lang: localLang, IS_RW, IS_SUGGESTED } = locals
    const { product } = item

    const isInversion = IS_SUGGESTED !== 'special_de' && item.is_inversion === 1
    const isSpecialDe = IS_SUGGESTED === 'special_de' && ![1, 3, 5].includes(item.suggestedPriceType)
    const isPremiumMember = item.promotionTypeIds.includes(29) || item.isSheinClubGiftItem
    const blackFridayImg = blackFridayImgGoods[product.goods_id]?.promoLabel?.src || ''
    // 转换闪购类型, 0: 非闪购, 1: 普通闪购, 2: 新人闪购, 3: 特殊闪购, 4: 分人群专区闪购, 5: 不分人群专区闪购, -1: 直播闪购
    const flashSaleType = item.promotionTypeIds.includes(10) ? item.flashType : (item.promotionTypeIds.includes(11) ? -1 : 0)
    const cartDiscountShow = product.sale_discount && item.promotionTypeIds.includes(3) && item.product.sale_discount != item.product.unit_discount
    const cartPromotionDiscount = underPriceAbtValue && item.promotionTypeIds.includes(30) && product.unit_discount
    const specialCouponDiscount = item.promotionTypeIds.includes(32) && product.unit_discount

    function getFlashSaleItemNum() {
      // wiki.pageId=843279127
      const promotionFlash = product.product_promotion_info.filter(item => [10, 11].includes(item.type_id))
      const { stock = 0, sold_num = 0 } = promotionFlash[0] || {}
      const realTimeInventory = +item.realTimeInventory || 0
      const flashNum = stock - sold_num
      const minNum = Math.min(realTimeInventory, flashNum)
      const maxNum = Math.max(realTimeInventory, flashNum)
      return minNum > 0 ? minNum : maxNum
    }

    function getSeriesFlag() {
      const { series_info_list = [], corner_brand_right, corner_brand_left, corner_series_right, corner_series_left } = product

      // 1.没有系列角标，返回普通品牌角标
      if (!series_info_list || !series_info_list.length) {
        return GB_cssRight ? corner_series_right || corner_brand_right : corner_series_left || corner_brand_left
      }
      // 2.针对系列角标排序
      const _series_info_list = JSON.parse(JSON.stringify(series_info_list))  // TODO: 勿操作原数据引起更新
      _series_info_list.sort((a, b) => {
        if (a.series_type === b.series_type) {
          return b.logo_show_position - a.logo_show_position
        }
        return b.series_type - a.series_type
      })
      return GB_cssRight ? _series_info_list[0].corner_series_right : _series_info_list[0].corner_series_left
    }

    // 角标样式
    function discountClass() {
      if (cartDiscountLogoA) {
        return {
          'a-new-discount': true,
          'a-fs-discount': [-1, 1, 4, 5].includes(flashSaleType),
          'a-fs-new__discount': [2, 3].includes(flashSaleType),
          'a-cart__discount': cartDiscountShow || cartPromotionDiscount || specialCouponDiscount || flashSaleType === 6,
          'a-premium-member__discount': isPremiumMember
        }
      } else {
        return {
          'fs-discount': [-1, 1, 4, 5].includes(flashSaleType),
          'fs-new-discount': [2, 3].includes(flashSaleType),
          'cart__discount': cartDiscountShow || cartPromotionDiscount || specialCouponDiscount || flashSaleType === 6,
          'premium-member-discount': isPremiumMember
        }
      }
    }

    const seriesFlag = getSeriesFlag()
    const flashSaleItemNum = getFlashSaleItemNum()

    // 系列角标
    const renderSeriesFlag = () => {
      if (blackFridayImg) {
        return <img class="product-series-flag black-fridayImg-flag" src={transformImg({ img: blackFridayImg })} aria-hidden="true" />
      } else if (seriesFlag) {
        return <img class="product-series-flag" src={transformImg({ img: seriesFlag })} aria-hidden="true" />
      }
    }
    // 商品图
    const renderGoodsImg = () => {
      if(isFirstPage){
        return <img
          class="hydrate_img fsp-element"
          src={baseCutImg}
          alt={product.goods_name}
          aria-hidden="true"
          fsp-key={`cart_item_id_${item.id}`}
        />
      } else {
        return <img
          class="lazyload"
          src={LAZY_IMG}
          data-src={getMainImageUrl({ item, IS_RW, customServiceImgDomains, thirdPartyServiceImgDomains })}
          fetchpriority="high"
          alt={product.goods_name}
          data-design-width={750}
          data-exp="cutTo=240#max=360"
        />
      }
    }
    // 折扣角标
    const renderDiscount = () => {
      if (isNewCartDiscountStyle) return // 开启折扣标签不显示折扣角标
      if (item.isGiftItem) return // 赠品不显示折扣角标
      if (IS_SUGGESTED && (isInversion || isSpecialDe)) return
      const renderDiscountContent = () => {
        const unitDiscount = getDiscountText(localLang, GB_cssRight, product.unit_discount)
        const saleDiscount = getDiscountText(localLang, GB_cssRight, product.sale_discount)
        if (cartDiscountShow) {
          return (
            <div>
              <span class="unit-discount">{unitDiscount}</span>
              <span class="cart__discount-sale">{saleDiscount}</span>
            </div>
          )
        } else {
          if (isNewPrice && !!+(item.single_coupon_discount?.amount)) {
            if (Number(item?.pre_coupon_discount_rate) > 0) {
              const discount =  getDiscountText(localLang, GB_cssRight, item?.pre_coupon_discount_rate)
              return <span class="unit-discount">{discount}</span>
            }
          } else {
            return <span class="unit-discount">{unitDiscount}</span>
          }
        }
      }
      return (
        <div
          v-show={product.unit_discount != 0}
          class={['new-discount', discountClass()]}
          aria-hidden="true"
        >
          {renderDiscountContent()}
        </div >
      )
    }
    // 告罄 / 下架
    const renderSoldOut = () => {
      const ctx = {
        language,
        soldoutIds,
        locals,
        flashSaleType,
        flashSaleItemNum,
        cartDiscountLogoA
      }
      return (
        <ImgStockBar
          item={item}
          ctx={ctx}
        />
      )
    }

    return (
      <a
        class={[
          'left-img',
          'j-scroll__cart-target',
          'new-cart-img',
          {
            'sold-out-img': (item.product.is_on_sale == '0' || soldoutIds.includes(item.id)),
            'new-cartrow-vision': isNewCartrowVision,
          }
        ]}
      >
        {renderGoodsImg()}
        <div class="new-cart-img__mask" />
        <ClientOnly>
          {renderSeriesFlag()}
        </ClientOnly>
        {renderDiscount()}
        {renderSoldOut()}
      </a>
    )
  }
}
</script>

<style lang="less">
.left-img {
  position: relative;
  width: 2.56rem;
  height: 3.41rem;
  .flexbox();

  img {
    width: 100%;
    display: block;
  }

  .product-series-flag {
    position: absolute;
    top: 4px;
    right: 4px/* rtl:ignore */;
    width: 32px;
    height: auto;

    &.black-fridayImg-flag {
      top: 0;
      right: 0/* rtl:ignore */;
    }
  }

  &.new-cart-img {
    display: flex;
    align-items: center;
    width: 148/75rem;
    height: 148/75rem;
    overflow: hidden;

    .new-cart-img__mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .03);
    }
  }
}

.new-discount {
  position: absolute;
  right: auto;
  text-align: center;
  color: @color_white_mirror;
  direction: ltr/* rtl:ltr */;
  .font-dpr(24px);
  /* sh:begin */
  min-width: 2.75em;
  line-height: 1.35;
  top: 4px;
  left: 0/* rtl:ignore */;
  right: unset
    /* rtl:ignore */
  ;
  background: #333;

  .unit-discount {
    display: block;
  }

  &.fs-discount {
    background: @sui_color_flash;
    color: #333;
    /* rw:begin */
    color: @sui_color_white;
  }

  &.fs-new-discount {
    background: @sui_color_discount;
    color: @sui_color_white;
  }

  &.a-new-discount, &.a-fs-discount, &.a-fs-new__discount {
    background: #FA6338;
    color: #fff;
    /* rw:begin */
    background: #FC4070;
  }

  &.cart__discount {
    background: @sui_color_promo;
    /* sh:begin */
    width: 32px;
    min-width: auto;
    /* sh:end */
    /* rw:begin */
    color: @sui_color_white;
    background: @sui_color_main;
  }

  &.a-cart__discount {
    // background: @sui_color_promo;
    /* sh:begin */
    width: 32px;
    min-width: auto;
    background: #FA6338;
    color: #fff;
    /* sh:end */
    /* rw:begin */
    background: #FC4070;
  }
  /* sh:end */
  /* rw:begin */
  padding: 0 5/75rem;
  right: unset
  /* rtl:ignore */
  ;
  line-height: 32/75rem;
  background: @sui_color_main;
  top: 0;
  left: 0
  /*rtl:ignore*/
  ;
  color:@sui_color_white;
  font-family: Adieu-Bold,
  Adieu;
  font-weight: bold;
  font-size: 9px;
}

.cart__discount-sale {
  text-decoration: line-through;
  opacity: 0.8;
  display: block;
  /* rw:begin */
  font-family: Adieu-Regular, Adieu;
  font-weight: 400;
}

.cart-item__runOut {
  position: absolute;
  top: 0;
  .left(0);
  width: 100%;
  height: 100%;
  background: fade(#000, 30%);
  line-height: 0.4rem;
}

.cart-item__runOut-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.15rem 0.3rem;
  min-width: 2.2rem;
  // background: fade(#fff,90%);
  text-align: center;
  max-width: 90%;
  color: #fff;
}

.cart-item__runOut-icon[class*="iconfont"] {
  display: block;
  color: #fff;
  .font-dpr(72px);
  line-height: .96rem;
}

.cart-item__runOut-tip {
  color: #fff;
  font-weight: bold; // 白白要求的
}

.cart-item__insufficientStock {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, .6);
  padding: 4/75rem 12/75rem;
  line-height: 1;
  color: #fff;
  .font-dpr(24px);
  width: 100%;
  text-align: center;

  // span {
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   display: -webkit-box;
  //   -webkit-line-clamp: 1;
  //   -webkit-box-orient: vertical;
  // }

  &.cart-item__few {
    background: rgba(250, 99, 56, 0.8);

    & when (@IS_RW) {
      background: rgba(255, 105, 110, 0.8);
    }

    &.cart-item__flash {
      background: rgba(252, 211, 100, 0.9);

      & when (@IS_SH) {
        color: #222;
      }

      & when (@IS_RW) {
        background: rgba(252, 64, 112, 0.8)
      }
    }
  }
}

.sold-out-img {

  img,
  .new-discount {
    opacity: .4;
  }
}
</style>

<style lang="less" scoped>
.new-discount {
  &.premium-member-discount {
    background: #FFE2D3;
    color: #873C00;
  }

  &.a-premium-member__discount {
    background: #873C00;
    color: #fff
  }
}
.new-cartrow-vision {
  width: 160/75rem;
  height: 160/75rem;
  .new-discount {
    top: 0!important;
  }
}
</style>
