<template>
  <!-- 包一层div，用于transiont高度的动画，最外层padding影响过渡卡顿 -->
  <div>
    <div
      v-enterkey
      :class="[
        'free-ship',
        'new-free-ship',
        oldRightsFuns ? 'old-rights-funs' : '',
        {'free-ship_activity': showCartActivityColor}
      ]"
      @click="clickCartTip"
    >
      <!-- 老样式：icon为黑色线条状，字体不加粗 -->
      <template v-if="!oldRightsFuns">
        <slot>
          <i
            class="suiiconfont item-icon"
            :class="[!showShipSupplement ? 'sui_icon_selected_16px' : 'sui_icon_shipping_green_18px sui_icon_shipping_18px']"
            aria-hidden="true"
          ></i>
        </slot>
        <div
          v-if="freeShippinpTips"
          class="ship-content"
          :class="{'ship-content_activity': showCartActivityColor}"
        >
          <div 
            class="tips" 
            v-html="freeShippinpTips"
          >
          </div>
          <div
            v-if="showCountDown && countDownTime > 0"
            :class="{'float-count-down': floatCountDown}"
          >
            <ClientOnly>
              <count-down
                :is-left-time="true"
                :time-stamp="countDownTime"
                :type="countDownType"
              />
            </ClientOnly>
          </div>
        </div>
      </template>
      <!-- 新样式：icon为绿色，重要字体加粗绿色 -->
      <template v-else>
        <div class="icon-tip-wrap">
          <slot>
            <Icon
              is-responsive-name
              class="item-icon"
              :name="!showShipSupplement ? 'sui_icon_selected_16px': 'sui_icon_freeshipping_16px_color'"
              size="16px"
              color="#0E8C33"
            />
          </slot>
          <div
            v-if="freeShippinpTips"
            class="ship-content"
            :class="{'ship-content_activity': showCartActivityColor}"
          >
            <div 
              class="tips" 
              v-html="freeShippinpTips"
            >
            </div>
            <div
              v-if="showCountDown && countDownTime > 0"
              :class="[{'float-count-down': floatCountDown}]"
            >
              <ClientOnly>
                <count-down
                  :is-left-time="true"
                  :time-stamp="countDownTime"
                  :type="countDownType"
                />
              </ClientOnly>
            </div>
          </div>
        </div>
      </template>
      <a
        v-if="showShipSupplement"
        :class="[
          'cart-tip__operation',
          oldRightsFuns ? 'cart-tip__operation_new' : ''
        ]"
        tabindex="0"
        :aria-label="language.SHEIN_KEY_PWA_15091"
      >
        <span :class="{ 'free-shipping-button': oldRightsFuns }">
          {{ language.SHEIN_KEY_PWA_15091 }}
        </span>
        <sui_icon_more_right_12px
          size="12px"
          :is-rotate="locals.GB_cssRight"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import countDown from './countDown.vue'
import ClientOnly from 'vue-client-only'
import { Icon, sui_icon_more_right_12px } from '@shein-aidc/icon-vue2'

export default {
  name: 'NewFreeShip',
  components: {
    ClientOnly,
    countDown,
    Icon,
    sui_icon_more_right_12px,
  },
  props: {
    floatCountDown: {
      type: Boolean,
      default: false,
    },
    // 免邮模块的类型 activity: 免邮活动  coupon: 邮费券
    type: {
      type: String,
      default: '',
    },
    // 提示
    freeShippinpTips: {
      type: String,
      default: '',
    },
    // 是否展示凑单入口
    showShipSupplement: {
      type: Boolean,
      default: false,
    },
    // 是否展示倒计时
    showCountDown: {
      type: Boolean,
      default: false,
    },
    // 倒计时时间
    countDownTime: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/prop-name-casing
    _originInfo: { // 原始数据
      type: Object,
      default: () => ({}),
    },
    shippingMethod: {
      type: String,
      default: '',
    },
    showWay: {
      type: String,
      default: '',
    },
    // 是否在弹窗中
    isInModal: {
      type: Boolean,
      default: false,
    },
    showPosition: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('cart', ['isPlatForm', 'language', 'mallList', 'mallShippingInfo', 'locals']),
    ...mapGetters('cart', ['singleMallCode', 'cartShippingTop', 'cartAddTradeGoodsDetailSwitch', 'oldRightsFuns', 'isShowCartActivityColor']),
    countDownType() {
      return this.showCartActivityColor ? 'black' : (this.floatCountDown ? 'green' : 'white')
    },
    showCartActivityColor(){
      return this.isShowCartActivityColor && !this.isInModal && this.showPosition != 'top'
    }
  },
  watch: {
    freeShippinpTips: {
      immediate: true,
      handler(n) {
        if ( n && typeof window != 'undefined') {
          daEventCenter.triggerNotice({
            daId: '1-8-5-3',
            extraData: this.getExtraData(),
          })
        }
      }
    }
  },
  methods: {
    ...mapMutations('cart', [ 'updateState' ]),
    clickCartTip() {
      if (!this.showShipSupplement) return

      daEventCenter.triggerNotice({
        daId: '1-8-5-4',
        extraData: this.getExtraData(),
      })

      if (this.type === 'coupon') {
        // 打开凑单弹窗
        this.updateState({
          key: 'addOnInfo', 
          value: {
            show: true,
            coupon: this._originInfo?.coupon,
            state: 'coupon_helper_add',
            config: {
              isClickToDetail: this.cartAddTradeGoodsDetailSwitch
            }
          }
        })
        return
      }

      // 首单免邮展示添加商品弹窗
      if (this._originInfo?.firstFreeShipping?.firstFreeShippingTip) {
        this.handleSupplement(998)
        return
      }

      this.handleSupplement(999)
    },
    // 展示免邮凑单
    handleSupplement(addType) {
      this.updateState({ key: 'freeShippingAddOnType', value: this.isPlatForm ? 'platform' : this.singleMallCode })
      this.updateState({ key: 'isMoreMallFreeShippingAddOn', value: false })
      this.$emit('addOn', { promotion_id: addType })
    },
    getExtraData() {
      let is_fullshippingactivity = 0
      let origin_price = ''
      let shipping_method = ''
      let mall_code = ''
      let shipping_free_type = ''

      if (this.type === 'coupon') {
        is_fullshippingactivity = this.showShipSupplement ? 0 : 1
        origin_price = this._originInfo?.other_coupon_rule?.[0]?.minOrderPrice?.amountWithSymbol || ''
        shipping_method = this._originInfo?.shipping_list?.map(item => item.shipping_method_name)?.join(',') || ''
        mall_code = this._originInfo?.mall?.mall_code || '',
        shipping_free_type = this._originInfo?.coupon_dimension == 1 ? 0 : 3
      } else {
        is_fullshippingactivity = this.showShipSupplement ? 0 : 1
        origin_price = this._originInfo?.shippingInfoAvailable?.origin_price?.amountWithSymbol || '-'
        shipping_method = this.shippingMethod || this._originInfo?.shippingInfoAvailable?.shipping_method_name || ''
        mall_code = this.isPlatForm ? '' : this.singleMallCode,
        shipping_free_type = this.isPlatForm ? 0 : 3
      }

      return {
        type: this.type,
        is_fullshippingactivity,
        mall_code,
        origin_price,
        shipping_method,
        show_position: this.oldRightsFuns ? 4 : (this.cartShippingTop ? 1 : (this.floatCountDown ? 2 : 3)),
        shipping_free_type,
        show_way: this.showWay,
      }
    }
  },
}
</script>

<style lang="less" scoped>
.free-ship {
  padding: .27rem .32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: @sui_color_safety_bg;
  &_activity {
    border-radius: 12/37.5rem 12/37.5rem 0 0;
    background: @sui_color_safety;
    color: @sui_color_white;
    .cart-tip__operation {
      color: @sui_color_white;
    }
  }
  .item-icon {
    font-size: 16px !important;/* stylelint-disable-line declaration-no-important */
    line-height: 1;
    margin-right: 0.21rem;
  }
  .ship-content{
    flex: 1;
    text-align: left;
    margin-right: .32rem;
    color: #222;
    font-size: 12px;
    overflow: hidden;
    &_activity {
      color: @sui_color_white;
    }
    .tips {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
    .float-count-down {
      position: absolute;
      right: 0rem;
      top: -0.34rem;
    }
  }
  .cart-tip__operation {
    text-decoration: none;
    font-weight: bold;
    color: #222;
    white-space: nowrap;
    .font-dpr(24px);
    .margin-l(0.32rem);
  }
  &.old-rights-funs {
    padding: 16/75rem 24/75rem;
    background: rgba(255, 243, 211, 1);
    .icon-tip-wrap {
      display: inline;
      overflow: hidden;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
      line-height: 34/75rem;
      .item-icon {
        display: inline;
        line-height: 34/75rem!important;
        margin-right: 4/75rem;
      }
      .ship-content {
        display: inline;
        .tips {
          display: inline;
        }
      }
    }
    .ship-content {
      margin-right: 0;
    }
    .cart-tip__operation {
      margin-left: 8/37.5rem;
    }
  }
}
.old-rights-funs .tips {
  ::v-deep strong {
    font-weight: 600;
    color: #0E8C33;
  }
}
.cart-tip__operation_new {
  max-width: 140/75rem;
  display: flex;
}
.free-shipping-button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
