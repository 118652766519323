var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warehouse-container"},[_c('div',{staticClass:"warehouse-content",class:[`j-warehouse-content-${_vm.mallInfo.mall_code}`]},[_vm._t("default"),_vm._v(" "),_c('div',{staticClass:"warehouse-title"},[_c('span',{staticClass:"mall-name"},[_vm._v(_vm._s(_vm.mallInfo.mall_name))]),_vm._v(" "),(_vm.isLocalShipment)?_c('s-label',{staticStyle:{"font-weight":"normal"},attrs:{"type":"success"}},[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_20949)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.showQuickMallBtn)?_c('span',{directives:[{name:"expose",rawName:"v-expose",value:({
          id: 'expose_mall_quickoperation.page_cart',
          data: {
            mall_code: _vm.mallInfo.mall_code
          }
        }),expression:"{\n          id: 'expose_mall_quickoperation.page_cart',\n          data: {\n            mall_code: mallInfo.mall_code\n          }\n        }"},{name:"tap",rawName:"v-tap",value:({
          id: 'click_mall_quickoperation.page_cart',
          data: {
            mall_code: _vm.mallInfo.mall_code
          }
        }),expression:"{\n          id: 'click_mall_quickoperation.page_cart',\n          data: {\n            mall_code: mallInfo.mall_code\n          }\n        }"}],staticClass:"quick-anchor",on:{"click":function($event){return _vm.anchorToMall(_vm.anotherMallInfo)}}},[_c('span',{staticClass:"text"},[_vm._v("\n          "+_vm._s(_vm.anotherMallInfo.mall_name)+"\n        ")]),_vm._v(" "),_c('img',{attrs:{"src":_vm.quickIconImg,"width":"12","height":"12"}})]):_vm._e()],1)],2),_vm._v(" "),_c('platform-shipping',{attrs:{"mall-code":_vm.mallCode}}),_vm._v(" "),_c('full-court-map',{attrs:{"mall-code":_vm.mallCode,"is-single-mall":false}}),_vm._v(" "),(_vm.isPlatForm)?[(_vm.sheinClubThresholdFullGiftPromotion)?_c('full-gift-promotion-top',{attrs:{"integrity-promotion-info":_vm.sheinClubThresholdFullGiftPromotion,"is-single-mall":false}}):_vm._e(),_vm._v(" "),(_vm.sheinClubFullGiftPromotion)?_c('full-gift-promotion-top',{attrs:{"integrity-promotion-info":_vm.sheinClubFullGiftPromotion,"is-single-mall":false}}):_vm._e(),_vm._v(" "),(_vm.fullGiftPromotion)?_c('full-gift-promotion-top',{attrs:{"integrity-promotion-info":_vm.fullGiftPromotion,"is-single-mall":false}}):_vm._e(),_vm._v(" "),(_vm.fullAddOnPromotion)?_c('add-on-promotion-top',{attrs:{"integrity-promotion-info":_vm.fullAddOnPromotion,"is-single-mall":false}}):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }