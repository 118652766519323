<script>
import { mapGetters, mapMutations } from 'vuex'
import { isEmpty } from '@shein/common-function'
import FreeShip from '../cartActivity/freeShip.vue'
import NewFreeShip from '../cartActivity/NewFreeShip.vue'

export default {
  name: 'TopFreeShip',
  components: {
    FreeShip,
    NewFreeShip,
  },
  computed: {
    ...mapGetters('cart', [
      'newFreeShippingInfo',
      'satisfyFreeShipping',
      'isShowNewFreeShip',
      'topShippingInfo',
    ]),
    componentName() {
      if (isEmpty(this.topShippingInfo) && isEmpty(this.newFreeShippingInfo)) return null
      if (this.isShowNewFreeShip && !this.newFreeShippingInfo?.freeShippinpTips) return null
      if (!this.isShowNewFreeShip && !this.topShippingInfo?.shippingTip) return null
      return this.isShowNewFreeShip ? 'NewFreeShip' : 'FreeShip'
    }
  },
  methods: {
    ...mapMutations('cart', ['updateState']),
    handleAddOn(promotion) {
      if (!promotion || !promotion.promotion_id) return
      this.updateState({
        key: 'addOnInfo', 
        value: {
          show: true,
          promotionId: promotion.promotion_id,
        }
      })
    }
  },
}
</script>

<template>
  <div v-if="componentName">
    <component
      :is="componentName"
      show-position="top"
      v-bind="{ ...newFreeShippingInfo }"
      @addOn="handleAddOn"
    />
  </div>
</template>
